<template>
    <modal ref="cancelar_tendero2" titulo="Cancelar pedido" icon="close" @guardar="cancelar_pedido">
        <ValidationObserver ref="form2">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center">
                    Es una lástima que quieras cancelar el pedido, cuéntanos por que tomas esta decisión.
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label>Motivo de la cancelación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="motivo">
                        <el-select v-model="model.id_motivo" size="small" class="w-100">
                            <el-option
                            v-for="item in motivos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label>Justificación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="justificacion">
                        <el-input v-model="model.justificacion" type="textarea" show-word-limit maxlength="250" :rows="3" />
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            model:{
                id_motivo:null,
                justificacion:'',

            },
            motivos:[]
        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_historial_tendero/pedido',
        }),
        id_pedido(){
            return this.pedido.id_pedido
        },
        id_admin_pedido(){
            return this.pedido.id
        }
    },
    methods:{
        toggle(){
            //console.log(this.pedido);
            this.select_motivos()
            this.model = {
                id_motivo:null,
                justificacion:''
            }
            this.$refs.cancelar_tendero2.toggle()
        },
        async select_motivos(){
            try {
                let params = {
                    tipo:3
                }
                const {data} = await Pedidos.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        async cancelar_pedido(){
            try {
                const valid = await this.$refs.form2.validate()
                if(valid){
                    if(this.id_pedido === null){
                        this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                        return
                    }
                    const {data} = await Pedidos.cancelar_tendero(this.id_pedido,this.model)
                    this.$emit('update')
                    this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_productos',this.id_admin_pedido)
                    this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_historial',this.id_admin_pedido)
                    this.notificacion('Mensaje','Pedido Cancelado','success')
                    this.$refs.cancelar_tendero2.toggle()
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
